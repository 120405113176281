import { CheckoutSessionPaymentResponse } from '@adyen/adyen-web/dist/types/types'
import { AddressCreate, LineItem, OrderUpdate } from '@commercelayer/sdk'
import useSWR from 'swr'
import { getOrderKey } from '../config'
import { ResultCode } from './use-adyen'
import { useClient } from './use-commerce-layer'

type AddressUpsert = AddressCreate & { id?: string }

export type Metadata = {
  deliveryMatchId: string // todo: make snake_case in the backend for consistency
  shipping_method: string
  method_id: string
  method_name?: string
  method_street?: string
  method_house_number?: string
  method_postal_code?: string
  method_country?: string
  method_city?: string
} | null

const shipmentInclude = [
  'available_shipping_methods',
  'shipment_line_items.line_item',
  'shipping_method',
  'stock_transfers.line_item',
  'stock_location',
  'parcels.parcel_line_items',
]

const include = [
  'billing_address',
  'shipping_address',
  'line_items.item',
  'line_items.item.shipping_category',
  'payment_method',
  'payment_source',
  'available_payment_methods',
  'available_customer_payment_sources',
  'line_items.line_item_options.sku_option',
  ...shipmentInclude.map((i) => `shipments.${i}`),
]

export const useCurrentOrder = () => {
  const client = useClient()
  const { data: order, ...swr } = useSWR(
    'order',
    (_) => {
      const id = window.localStorage.getItem(getOrderKey())
      return id ? client.orders.retrieve(id, { include }) : undefined
    },
    { revalidateOnFocus: false }
  )

  return {
    order,

    /**
     * Create order and associate to Adyen
     */
    createOrder: async () => {
      const paymentMethod = await client.payment_methods
        .list({ filters: { payment_source_type: 'adyen_payments' } })
        .then((methods) => methods[0])

      const order = await client.orders.create(
        {
          payment_method: client.payment_methods.relationship(paymentMethod),
        },
        { include }
      )

      window.localStorage.setItem(getOrderKey(), order.id)
      swr.mutate(order)

      return order
    },

    /**
     * Update the order and the addresses
     *
     * 1. First update/create the addresses
     * 2. Then update the order with references to the addresses
     * 3. Update the shipping method if provided for the (new) shipping address
     * 4. Update the cache with the latest order data
     */
    updateOrder: async (
      orderUpdate: OrderUpdate,
      {
        billingAddressUpdate,
        shippingAddressUpdate,
        shippingMethodId,
        metadata,
        paymentMethodId,
        giftCardCode,
        couponCode,
        giftCardOrCouponCode,
      }: {
        billingAddressUpdate?: AddressUpsert
        shippingAddressUpdate?: AddressUpsert
        shippingMethodId?: string
        metadata?: Metadata
        paymentMethodId?: string
        giftCardCode?: string
        couponCode?: string
        giftCardOrCouponCode?: string
      }
    ) => {
      if (!order) {
        return
      }

      // create or update billing address if provided
      const billingAddress = billingAddressUpdate
        ? billingAddressUpdate.id
          ? await client.addresses.update({ ...billingAddressUpdate, id: billingAddressUpdate.id })
          : await client.addresses.create(billingAddressUpdate)
        : undefined

      // create or update shipping address if provided and it is not the same as billing
      const shippingAddress =
        shippingAddressUpdate && !orderUpdate._shipping_address_same_as_billing
          ? shippingAddressUpdate.id
            ? await client.addresses.update({
                ...shippingAddressUpdate,
                id: shippingAddressUpdate.id,
              })
            : await client.addresses.create(shippingAddressUpdate)
          : undefined

      // update the order itself with the proper references to the addresses
      const updatedOrder = await client.orders.update(
        {
          ...orderUpdate,
          coupon_code: couponCode ?? (order.gift_card_code ? giftCardOrCouponCode : undefined),
          gift_card_code: giftCardCode,
          gift_card_or_coupon_code: order.gift_card_code ? undefined : giftCardOrCouponCode,
          payment_method: paymentMethodId
            ? client.payment_methods.relationship(paymentMethodId)
            : undefined,
          billing_address: billingAddress?.id
            ? client.addresses.relationship(billingAddress.id)
            : undefined,
          shipping_address:
            !orderUpdate._shipping_address_same_as_billing && shippingAddress?.id
              ? client.addresses.relationship(shippingAddress.id)
              : undefined,
          metadata: metadata ?? {},
        },

        { include }
      )

      // update shipping method if provided
      if (updatedOrder?.shipments && shippingMethodId) {
        updatedOrder.shipments = await Promise.all(
          updatedOrder.shipments.map((shipment) =>
            client.shipments.update(
              {
                id: shipment.id,
                shipping_method: client.shipping_methods.relationship(shippingMethodId),
              },
              { include: shipmentInclude }
            )
          )
        )

        if (!updatedOrder.payment_source) {
          await client.adyen_payments.create({ order: client.orders.relationship(updatedOrder) })
        }
      }

      // Update cache
      await swr.mutate(updatedOrder)

      return updatedOrder
    },

    /**
     * Update the line item quantity or delete a line item
     */
    updateLineItemQuantity: (lineItem: LineItem, quantity: number) =>
      quantity === 0
        ? // Send a delete line item request if quantity is zero
          client.line_items.delete(lineItem.id).then(() =>
            swr.mutate((order) => ({
              ...order!,
              line_items: order?.line_items?.filter((li) => li.id !== lineItem.id),
            }))
          )
        : // Otherwise update the line item quantity
          client.line_items
            .update({
              id: lineItem.id,
              quantity,
            })
            .then(() =>
              swr.mutate((order) => ({
                ...order!,
                line_items: order?.line_items?.map((li) => (li.id === lineItem.id ? lineItem : li)),
              }))
            ),

    ...swr,
  }
}

export const useOrder = (id: string | null) => {
  const client = useClient()

  const { data: order, ...swr } = useSWR(
    id ? `order-${id}` : null,
    (_) => client.orders.retrieve(id!, { include }),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    order,
    ...swr,
    // For iDeal we need to store the redirect result in the payment source, before placing the order.
    saveRedirectResult: async (redirectResult: string) => {
      if (!order) {
        return 'Error'
      }
      const paymentResponse = await client.adyen_payments
        .update({
          id: order.payment_source!.id,
          payment_request_details: {
            details: {
              redirectResult,
            },
          },
          // @ts-ignore
          _details: 1,
        })
        .then((rep) => rep.payment_response as CheckoutSessionPaymentResponse)

      return paymentResponse.resultCode as ResultCode
    },
    /**
     * Finalize an order by updating the payment source with the redirect result and placing the order
     */
    placeOrder: async () => {
      if (!order) {
        return
      }

      const placedOrder = await client.orders.update({ _place: true, id: order.id }, { include })

      swr.mutate()

      return placedOrder
    },
  }
}
